/* eslint-disable no-restricted-syntax */
import {useCallback, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const useQueryMap = () => {
  const {pathname, search} = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const map = {};
    for (const [key, value] of searchParams.entries()) {
      map[key] = value;
    }
    return map;
  }, [search]);

  const changeQueryParams = useCallback(
    (newParams) => {
      const newSearch = new URLSearchParams({
        ...params,
        ...newParams,
      }).toString();
      navigate(`${pathname}?${newSearch}`);
    },
    [params, navigate, pathname],
  );

  return {params, navigate, change: changeQueryParams};
};
