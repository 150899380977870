import { createBrowserRouter } from "react-router-dom";
import {OverviewPage} from './App';
import {Homepage} from './Homepage';


export const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage />,
  },
  {
    path: '/overview',
    element: <OverviewPage />,
  },
]);