import {React, useEffect, useState} from 'react';
import '@radix-ui/themes/styles.css';
import { useQueryMap } from './hooks/useQueryParams';
import './theme-config.css'
import { Flex, Text, Button, Container, Heading, Box, Card, Avatar, Callout, Table, Dialog, Code } from '@radix-ui/themes';
import { useNavigate } from "react-router-dom";
import {formatEthAddress, balanceFormatter } from './utils';

const Spinner = () => (
    <center><svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg></center>
);

export function OverviewPage() {
    const {params: {wallets}} = useQueryMap(); 
    const [totalsByWallet, setTotalByWallet] = useState({});
    const [totals, setTotals] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        setLoading(true);
        fetch(`https://7xmgb7exfu77a3hyirk3g2pjci0dfpku.lambda-url.eu-west-1.on.aws`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({walletAddresses: wallets.split(',')})
        }).then((r) => r.json()).then(response => {
            if(response) {
                setTotals(response.totals);
                setTotalByWallet(response.totalResultPerWallet);
            }
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoading(false);
        });
    }, [ wallets]);

  return (
    <Container size="3" mt="9">
      <Flex direction="column" gap="3">
        <Box>
          <Heading as="h1" align="center" size="8">BONKLESS<span className="beta">BETA</span></Heading>
        </Box>

        {loading ? <Spinner /> : <>
          <Box align="center" mt="9">
            <Text as="p" size="5" weight="medium" mb="2">You have spent <span className="highlight">{balanceFormatter(totals?.totalSpentInGasInETH)}</span> ETH using <span className="highlight">{balanceFormatter(totals?.totalGasUsed)}</span> gas to send <span className="highlight">{totals?.totalTxs}</span> transactions.</Text>
            <Text as="p" size="5" weight="medium" mb="2">Right now, that's <span className="highlight">${balanceFormatter(totals?.totalSpentInGasInUsd)}</span>.</Text>
            <Text as="p" size="5" weight="medium" mb="2">If you had bought BONK instead, you would have made <span className="highlight">{balanceFormatter(totals?.totalProfitPercentage)}%</span> profit - or <span className="highlight">${balanceFormatter(totals?.totalProfitInUSD)}</span>.</Text>
          </Box>

          <Box width="50%" mx="auto" align="center" mb="3">
            <Button onClick={() => {navigate('/')}} size="3">Check again
              <svg width="16" height="16" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
            </Button>
          </Box>
        </>
        }
      </Flex>
      
      {loading ? null : <Box mt="5">
        <Table.Root variant="surface" size="1">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Wallet Address</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Gas Fees (ETH)</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Gas Fees (USD)</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Missed BONK Purchase</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Missed Profit (USD)</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {Object.keys(totalsByWallet).map(hash => (
            <Table.Row>
              <Table.RowHeaderCell>{formatEthAddress(hash)}</Table.RowHeaderCell>
              <Table.Cell>{balanceFormatter(totalsByWallet[hash].totalSpentInGasInETH)}</Table.Cell>
              <Table.Cell>{balanceFormatter(totalsByWallet[hash].totalSpentInGasInUsd)}</Table.Cell>
              <Table.Cell>{balanceFormatter(totalsByWallet[hash].totalBonkUsedInTx)}</Table.Cell>
              <Table.Cell>${balanceFormatter(totalsByWallet[hash].totalProfitInUSD)}</Table.Cell>
            </Table.Row>               
            ))}
            
          </Table.Body>
        </Table.Root>
      </Box>}
      

      <Box mt="9" mb="5">
        <Heading as="h2" align="center" size="6">Still Bonkless? Get some here:</Heading>
      </Box>

      <Flex direction="row" gap="3" justify="center" wrap="wrap">

        {/* Card 1 */}
        <Card asChild style={{ minWidth: 200, maxWidth: 400 }}>
          <a href="https://buybonk.com">
            <Flex direction="column" gap="3" align="center">
              <Avatar
                size="3"
                src="/buybonk.jpeg"
                radius="full"
                fallback="B"
              />
              <Box align="center">
                <Text as="div" size="2" weight="bold">
                  BuyBonk
                </Text>
                <Text as="div" size="2" color="gray">
                  buybonk.com
                </Text>
              </Box>
            </Flex>
          </a>
        </Card>

        {/* Card 2 */}
        <Card asChild style={{ minWidth: 200, maxWidth: 400 }}>
          <a href="https://bonkswap.io">
            <Flex direction="column" gap="3" align="center">
              <Avatar
                size="3"
                src="/bonkswap.jpeg"
                radius="full"
                fallback="B"
              />
              <Box align="center">
                <Text as="div" size="2" weight="bold">
                  BonkSwap
                </Text>
                <Text as="div" size="2" color="gray">
                  bonkswap.io
                </Text>
              </Box>
            </Flex>
          </a>
        </Card>

        {/* Card 3 */}
        <Card asChild style={{ minWidth: 200, maxWidth: 400 }}>
          <a href="https://jup.ag">
            <Flex direction="column" gap="3" align="center">
              <Avatar
                size="3"
                src="/jupiter.jpeg"
                radius="full"
                fallback="B"
              />
              <Box align="center">
                <Text as="div" size="2" weight="bold">
                  Jupiter
                </Text>
                <Text as="div" size="2" color="gray">
                  jup.ag
                </Text>
              </Box>
            </Flex>
          </a>
        </Card>

        {/* Card 4 */}
        <Card asChild style={{ minWidth: 200, maxWidth: 400 }}>
          <a href="https://t.me/bonkbot_bot?start=ref_oelzu">
            <Flex direction="column" gap="3" align="center">
              <Avatar
                size="3"
                src="/bonkbot.jpeg"
                radius="full"
                fallback="B"
              />
              <Box align="center">
                <Text as="div" size="2" weight="bold">
                  BonkBot
                </Text>
                <Text as="div" size="2" color="gray">
                  t.me/bonkbot_bot
                </Text>
              </Box>
            </Flex>
          </a>
        </Card>

      </Flex>

      <Callout.Root variant="soft" size="1" mt="9">
        <Callout.Icon>
          ⓘ
        </Callout.Icon>
        <Callout.Text>
          This BETA version can fetch prices going back to Feb 1st 2023 only. This will change in v1.0.
        </Callout.Text>
      </Callout.Root>

      <Flex direction="row" gap="3" justify="center" wrap="wrap" mt="5">
        <Dialog.Root>
          <Dialog.Trigger>
            <Button color="purple">Donate Solana</Button>
          </Dialog.Trigger>

          <Dialog.Content style={{ maxWidth: 600 }}>
            <Dialog.Title align="center">Donate Solana</Dialog.Title>
            <Dialog.Description size="2" mb="4" align="center">
              Please send SOL, BONK or any SPL Token to the following address:<br></br><br></br>
              <Code size="4" color="cyan">2kneckF5yovEs73JdXrBsjrU7cQqLMJjRZTBzMooHsU6</Code><br></br><br></br>
              Thank you!
            </Dialog.Description>
              <Box align="center">
              <Dialog.Close>
                <Button>Cancel</Button>
              </Dialog.Close>
              </Box>
          </Dialog.Content>
        </Dialog.Root>

        <Dialog.Root>
          <Dialog.Trigger>
            <Button color="gray">Donate Ethereum</Button>
          </Dialog.Trigger>

          <Dialog.Content style={{ maxWidth: 600 }}>
            <Dialog.Title align="center">Donate Ethereum</Dialog.Title>
            <Dialog.Description size="2" mb="4" align="center">
              <Text size="9">LOL</Text>
            </Dialog.Description>
              <Box align="center">
              <Dialog.Close>
                <Button>Cancel</Button>
              </Dialog.Close>
              </Box>
          </Dialog.Content>
        </Dialog.Root>
    </Flex>
    </Container>
  );
}
