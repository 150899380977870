import {React, useState} from 'react';
import '@radix-ui/themes/styles.css';
import './theme-config.css'
import { useNavigate } from "react-router-dom";
import { Code, Flex, Dialog, Text, Button, TextArea, Container, Heading, Box, Card, Avatar, Callout } from '@radix-ui/themes';

export function Homepage() {
  const [wallets, setWallets] = useState([]);
  const [ setError] = useState();
  const navigate = useNavigate();
  const check = () => {
      const addresses = [...new Set(wallets)];
      navigate('/overview?wallets=' + addresses.join(','));
  }

  return (
    <Container size="3" mt="9">
      <Flex direction="column" gap="3">
        <Box>
          <Heading as="h1" align="center" size="8">BONKLESS<span className="beta">BETA</span></Heading>
        </Box>

        <Box>
          <Text as="p" align="center" weight="medium" size="4" mt="9">Check your potential PNL if you had spent your ETH gas fees on <span className="highlight">BONK</span>.</Text>
          <TextArea onChange={(e) => {
                    const value = e.target.value;
                    const regex = /\b0x[a-fA-F0-9]{40}\b/g;
                    const addresses = value.match(regex);
                    if(addresses && addresses.length > 5) {
                        setError('Please enter max 5 addresses');
                        return;
                    }
                    setWallets(addresses || []);
                }} size="3" mt="9" mb="1" mx="9" placeholder="Enter up to 5 ETH wallet addresses" />
          
          {/* <Text as="p" align="center" size="1" color="red">This BETA version can fetch prices going back to Feb 1st 2023 only. This will change in v1.0.</Text> */}
        </Box>

        <Box width="50%" mx="auto" align="center">
          <Button onClick={check} size="3">Check</Button>
        </Box>
      </Flex>

      <Box mt="9" mb="5">
          <Heading as="h2" align="center" size="6">Still Bonkless? Get some here:</Heading>
      </Box>
      
      <Flex direction="row" gap="3" justify="center" wrap="wrap">

        {/* Card 1 */}
        <Card asChild style={{ minWidth: 200, maxWidth: 400 }}>
          <a href="https://buybonk.com">
            <Flex direction="column" gap="3" align="center">
              <Avatar
                size="3"
                src="/buybonk.jpeg"
                radius="full"
                fallback="B"
              />
              <Box align="center">
                <Text as="div" size="2" weight="bold">
                  BuyBonk
                </Text>
                <Text as="div" size="2" color="gray">
                  buybonk.com
                </Text>
              </Box>
            </Flex>
          </a>
        </Card>

        {/* Card 2 */}
        <Card asChild style={{ minWidth: 200, maxWidth: 400 }}>
          <a href="https://bonkswap.io">
            <Flex direction="column" gap="3" align="center">
              <Avatar
                size="3"
                src="/bonkswap.jpeg"
                radius="full"
                fallback="B"
              />
              <Box align="center">
                <Text as="div" size="2" weight="bold">
                  BonkSwap
                </Text>
                <Text as="div" size="2" color="gray">
                  bonkswap.io
                </Text>
              </Box>
            </Flex>
          </a>
        </Card>

        {/* Card 3 */}
        <Card asChild style={{ minWidth: 200, maxWidth: 400 }}>
          <a href="https://jup.ag">
            <Flex direction="column" gap="3" align="center">
              <Avatar
                size="3"
                src="/jupiter.jpeg"
                radius="full"
                fallback="B"
              />
              <Box align="center">
                <Text as="div" size="2" weight="bold">
                  Jupiter
                </Text>
                <Text as="div" size="2" color="gray">
                  jup.ag
                </Text>
              </Box>
            </Flex>
          </a>
        </Card>

        {/* Card 4 */}
        <Card asChild style={{ minWidth: 200, maxWidth: 400 }}>
          <a href="https://t.me/bonkbot_bot?start=ref_oelzu">
            <Flex direction="column" gap="3" align="center">
              <Avatar
                size="3"
                src="/bonkbot.jpeg"
                radius="full"
                fallback="B"
              />
              <Box align="center">
                <Text as="div" size="2" weight="bold">
                  BonkBot
                </Text>
                <Text as="div" size="2" color="gray">
                  t.me/bonkbot_bot
                </Text>
              </Box>
            </Flex>
          </a>
        </Card>

      </Flex>

      <Callout.Root variant="soft" size="1" mt="9">
            <Callout.Icon>
            ⓘ
            </Callout.Icon>
            <Callout.Text>
            This BETA version can fetch prices going back to Feb 1st 2023 only. This will change in v1.0.
            </Callout.Text>
          </Callout.Root>

          <Flex direction="row" gap="3" justify="center" wrap="wrap" mt="5">
        <Dialog.Root>
          <Dialog.Trigger>
            <Button color="purple">Donate Solana</Button>
          </Dialog.Trigger>

          <Dialog.Content style={{ maxWidth: 600 }}>
            <Dialog.Title align="center">Donate Solana</Dialog.Title>
            <Dialog.Description size="2" mb="4" align="center">
              Please send SOL, BONK or any SPL Token to the following address:<br></br><br></br>
              <Code size="4" color="cyan">2kneckF5yovEs73JdXrBsjrU7cQqLMJjRZTBzMooHsU6</Code><br></br><br></br>
              Thank you!
            </Dialog.Description>
              <Box align="center">
              <Dialog.Close>
                <Button>Cancel</Button>
              </Dialog.Close>
              </Box>
          </Dialog.Content>
        </Dialog.Root>

        <Dialog.Root>
          <Dialog.Trigger>
            <Button color="gray">Donate Ethereum</Button>
          </Dialog.Trigger>

          <Dialog.Content style={{ maxWidth: 600 }}>
            <Dialog.Title align="center">Donate Ethereum</Dialog.Title>
            <Dialog.Description size="2" mb="4" align="center">
              <Text size="9">LOL</Text>
            </Dialog.Description>
              <Box align="center">
              <Dialog.Close>
                <Button>Cancel</Button>
              </Dialog.Close>
              </Box>
          </Dialog.Content>
        </Dialog.Root>
    </Flex>

    </Container>
  );
}

