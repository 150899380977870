export const SIGNIFICANT_FIGURES = 4;

export const balanceFormatter = (input) => {
    if(input === undefined) {return 'N/A'}
    const balanceStr = input.toString();
    const balance = parseFloat(balanceStr);

    if (isNaN(balance)) {
        return "0";
    }

    let formatted;

    if (balance > 0 && balance < 1) {
        const nonZeroIndex = balanceStr.split('.')[1].search(/[1-9]/);
        const requiredDigits = nonZeroIndex + SIGNIFICANT_FIGURES;
        formatted = balance.toFixed(requiredDigits);
    } else {
        formatted = balance.toPrecision(Math.max(SIGNIFICANT_FIGURES, balance.toFixed(2).replace(/^-?0*/,'').length)); //remove leading 0s
        if(formatted.length > SIGNIFICANT_FIGURES + 1) {
            formatted = (+parseFloat(formatted).toFixed(2)).toString(); //round to 2 decimal places
        }
    }

    formatted = formatted.replace(/(\.\d*[1-9])0+$/, '$1'); //remove trailing 0s after decimal
    formatted = formatted.replace(/\.0+$/, ''); //remove all trailing 0s

    if (balance >= 1000) {
        formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return formatted;

}

export function formatEthAddress(address) {
    // Check if the address is valid
    if (!address || address.length !== 42) {
        return 'Invalid address';
    }

    // Define how many characters to show at the start and end of the address
    const charsToShow = 6;
    const start = address.substring(0, charsToShow);
    const end = address.substring(address.length - charsToShow);

    // Return the formatted address
    return `${start}...${end}`;
}